import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useContextualRouting } from 'next-use-contextual-routing';
import { useMount } from 'react-use';
import { Box, Button, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { getUserDetails, trackEvent, trackNavigatedToPage } from '@surfline/web-common';

import { fetchFavoriteSubregions, fetchForecasterContent } from 'actions/subregion';
import { DEFAULT_UNITS } from 'common/constants';
import { SL_WEB_CUSTOM_FORECASTS } from 'common/treatments';
import ControlBarScrollButtons from 'components/ControlBarScrollButtons';
import FavoriteRegionFilters from 'components/FavoriteRegionFilters';
import FavoritesCustomForecast from 'components/FavoritesCustomForecast';
import FavoritesForecast from 'components/FavoritesForecast';
import FavoritesLive from 'components/FavoritesLive';
import { EditIcon } from 'components/Icons/EditIcon';
import TickWithBorderIcon from 'components/Icons/TickWithBorderIcon';
import MetaTags from 'components/MetaTags';
import MissingFavourites from 'components/MissingFavourites';
import SubregionForecastContentNow from 'components/SubregionForecastContentNow';
import ModalContextual from 'components/ModalContextual';
import FavoritesForm from 'components/FavoritesForm';
import SubregionForecast from 'containers/SubregionForecast';
import SubregionForecastContentHighlights from 'components/SubregionForecastContentHighlights';
import { ForecastOutlookContextProvider } from 'contexts/ForecastOutlookContext';
import { PageContext } from 'contexts/PageContext';
import useConditions from 'hooks/useConditions';
import useFavorites from 'hooks/useFavorites';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';
import { getForecastContent, getForecastContentLoading } from 'selectors/subregion';
import { useUserPermissionStatus, useUserUnits } from 'selectors/user';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import type { UserFavorites } from 'types/userFavorites';
import type { Units } from 'types/units';
import type { ForecastContent } from 'types/subregion';
import { filterFavoritesByRegions } from 'utils/favorites';
import { useTreatments } from 'utils/treatments';

import { useForecastDaysAllowed } from 'utils/forecastDaysAllowed';
import styles from './Favorites.module.scss';

const defaultMissingArgs = {
  boldText: 'Looks a little empty in here.',
  normalText: 'Add some favorite spots to compare live conditions and forecasts.',
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </div>
);

interface FavoritesHeaderProps {
  isEditingFavorites: boolean;
  showEditButton: boolean;
  toggleIsEditing: () => void;
  userHasFavorites: boolean;
}

const FavoritesHeader: React.FC<FavoritesHeaderProps> = ({
  isEditingFavorites,
  showEditButton = true,
  toggleIsEditing,
  userHasFavorites,
}) => {
  const isMobile = useMaxWidthTablet();

  const buttonText = useMemo(() => {
    if (isEditingFavorites) return 'Done';
    if (isMobile) return 'Edit';
    return 'Edit Favorites';
  }, [isEditingFavorites, isMobile]);

  const buttonVariant = useMemo(() => {
    if (isEditingFavorites) return 'primary';
    if (isMobile) return 'text';
    return 'secondary';
  }, [isEditingFavorites, isMobile]);

  return (
    <div className={styles.header}>
      <Typography data-testid="favorites-headline" variant="h1">
        Favorites
      </Typography>
      {userHasFavorites && showEditButton && (
        <div
          className={classNames({
            [styles.headerButtons]: true,
            [styles.isEditingFavorites]: isEditingFavorites,
          })}
        >
          <Button
            className={styles.editButton}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={toggleIsEditing}
            variant={buttonVariant}
          >
            {!isMobile && isEditingFavorites && (
              <TickWithBorderIcon className={styles.editingIcon} />
            )}
            {!isMobile && !isEditingFavorites && <EditIcon className={styles.editIcon} />}
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

interface FavoritesProps {
  showMissingFavsWithLessThan?: number;
  surfHeightUnit: string;
}

const Favorites: React.FC<FavoritesProps> = ({
  showMissingFavsWithLessThan = 5,
  surfHeightUnit,
}) => {
  const scrollRef = useRef(null);
  const { push: routerPush, pathname, query } = useRouter();
  const { subregionForecast } = query;
  const { pageName } = useContext(PageContext);
  const { hasCoreForecastPermissions } = useUserPermissionStatus();
  const { themeClassName } = useConditions();
  const treatments = useTreatments();
  const slTheme = useTheme();
  const { makeContextualHref, returnHref } = useContextualRouting();
  const dispatch = useAppDispatch();

  const forecastContent: ForecastContent = useAppSelector(getForecastContent);
  const forecastContentLoading: boolean = useAppSelector(getForecastContentLoading);

  const { favorites, regions } = useFavorites({ allRegions: true });
  const userDetails = useAppSelector(getUserDetails);
  const userUnits = useUserUnits();

  const [selectedRegions, setSelectedRegions] = useState<Array<string>>(['all']);
  const [selectedFavorites, setSelectedFavorites] = useState<UserFavorites | undefined>(undefined);
  const [isEditingFavorites, setIsEditingFavorites] = useState(false);

  const { type } = query;
  const hasUserAccount = !!userDetails;
  const lessThanXFavorites = favorites && favorites.length < showMissingFavsWithLessThan;

  const noFavorites = useMemo(() => (favorites ? favorites.length < 1 : false), [favorites]);

  const { forecastDaysAllowed } = useForecastDaysAllowed({
    type: 'forecast-content',
    isEntitled: hasCoreForecastPermissions,
  });

  const tabs = useMemo(() => {
    const tabItems = [
      {
        label: 'Live',
        key: 'live',
      },
      {
        label: 'Forecast',
        key: 'forecast',
      },
    ];
    if (treatments[SL_WEB_CUSTOM_FORECASTS] === 'on') {
      tabItems.push({
        label: 'Custom Forecast',
        key: 'custom-forecast',
      });
    }
    return tabItems;
  }, [treatments]);

  const currentTabIndex = useMemo(() => {
    const index = tabs.findIndex(({ key }) => key === type);
    return index > -1 && type !== undefined ? index : 0;
  }, [tabs, type]);

  const [tab, setTab] = useState(currentTabIndex);

  useEffect(() => {
    setSelectedFavorites(filterFavoritesByRegions(favorites, selectedRegions));
  }, [selectedRegions, favorites]);

  const handleRegionSelected = useCallback(
    (event: React.MouseEvent<HTMLElement>, newRegions: string[] | string) => {
      trackEvent('Clicked Filter', {
        filterName: 'subregion',
        pageName: 'Favorites',
        tabName: tabs[tab].key,
      });
      if (
        Array.isArray(newRegions) &&
        (newRegions[newRegions.length - 1] === 'all' || newRegions.length === 0)
      ) {
        setSelectedRegions(['all']);
      } else if (Array.isArray(newRegions) && newRegions[0] === 'all') {
        setSelectedRegions(newRegions.slice(1));
      } else {
        setSelectedRegions(Array.isArray(newRegions) ? newRegions : [newRegions]);
      }
    },
    [tab, tabs],
  );

  const toggleIsEditing = useCallback(() => {
    setIsEditingFavorites((prev) => {
      if (prev === false) {
        trackNavigatedToPage('Edit Favorites', {});
      }
      return !prev;
    });
  }, []);

  const debouncedScrollHandler = useMemo(
    () =>
      debounce(
        (scrollDirection: string, action: string) => {
          trackEvent('Forecast Range Updated', {
            action,
            pageName,
            scrollDirection,
            uiElement: 'forecast outlook',
          });
        },
        1000,
        {
          leading: true,
          trailing: false,
        },
      ),
    [pageName],
  );

  const handleTabChange = useCallback(
    (_event: any, newType: number) => {
      const newQueryParam = tabs[newType].key;
      routerPush(makeContextualHref({ type: newQueryParam }), `/favorites?type=${newQueryParam}`, {
        shallow: true,
      });
      setTab(newType);
    },
    [tabs, routerPush, makeContextualHref],
  );

  const tabNav = useMemo(
    () => (
      <Tabs
        data-testid="favorites-tabs"
        indicatorColor="secondary"
        onChange={handleTabChange}
        textColor="secondary"
        value={tab}
      >
        {tabs.map(({ key, label }) => (
          <Tab
            className={styles.tab}
            data-testid="favorites-tab"
            disableFocusRipple
            disableRipple
            key={key}
            label={label}
          />
        ))}
      </Tabs>
    ),
    [handleTabChange, tab, tabs],
  );

  const shouldShowForecasterContent = useMemo(() => {
    let shouldShow = !!(
      selectedRegions &&
      selectedRegions.length === 1 &&
      selectedRegions[0] !== 'all'
    );

    if (shouldShow && selectedRegions && selectedRegions.length === 1) {
      const selectedRegion = regions.find((region) => region.id === selectedRegions[0]);
      shouldShow = !!(selectedRegion && selectedRegion.forecastStatus === 'active');
    }
    return shouldShow;
  }, [selectedRegions, regions]);

  useEffect(() => {
    if (shouldShowForecasterContent) {
      dispatch(
        fetchForecasterContent({
          cookies: undefined,
          days: forecastDaysAllowed,
          subregionId: selectedRegions[0],
        }),
      );
    }
  }, [
    forecastDaysAllowed,
    dispatch,
    hasCoreForecastPermissions,
    selectedRegions,
    shouldShowForecasterContent,
  ]);

  useMount(() => {
    dispatch(fetchFavoriteSubregions(undefined));
  });

  return (
    <Box className={classNames('sl-section-container', themeClassName)}>
      <MetaTags
        title="Surfline Favorite Surf Spots"
        description="Surfline Favorite Surf Spots"
        urlPath="/favorites"
      />
      <Box className={styles.favoritesHeader}>
        <FavoritesHeader
          isEditingFavorites={isEditingFavorites}
          showEditButton={currentTabIndex !== 2 && hasUserAccount}
          toggleIsEditing={toggleIsEditing}
          userHasFavorites={!noFavorites}
        />
        {!isEditingFavorites && tabNav}
      </Box>
      <Box className={styles.favoritesTabs}>
        {!isEditingFavorites ? (
          <>
            <div className={styles.headerWrap}>
              {tabs[tab]?.key !== 'custom-forecast' && !noFavorites && (
                <FavoriteRegionFilters
                  favoriteRegions={regions}
                  handleRegionSelected={handleRegionSelected}
                  selectedRegions={selectedRegions}
                />
              )}
              {shouldShowForecasterContent && (
                <Box className={styles.forecasterContent}>
                  {type === 'live' && (
                    <SubregionForecastContentNow
                      elevation={0}
                      forecastContent={forecastContent}
                      isLoading={forecastContentLoading}
                      isTitleOnly
                      pageName="Favorites"
                    />
                  )}
                  {type === 'forecast' && (
                    <SubregionForecastContentHighlights
                      elevation={0}
                      forecastContent={forecastContent}
                      isLoading={forecastContentLoading}
                      isTodayIncluded
                      pageName="Favorites"
                    />
                  )}
                </Box>
              )}
            </div>
            <TabPanel value={tab} index={0}>
              <FavoritesLive
                selectedFavorites={selectedFavorites}
                loading={false}
                error={undefined}
                units={(userUnits || DEFAULT_UNITS) as Units}
              />
              {lessThanXFavorites && (
                <MissingFavourites
                  boldText={defaultMissingArgs.boldText}
                  normalText={defaultMissingArgs.normalText}
                />
              )}
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <div className={styles.headerWrapForecastContent}>
                {tabs[tab]?.key !== 'live' && scrollRef && (
                  <ControlBarScrollButtons
                    debouncedScrollHandlers={{
                      left: (action: string) => debouncedScrollHandler('Left', action),
                      right: (action: string) => debouncedScrollHandler('Right', action),
                    }}
                    scrollRef={scrollRef.current}
                    sectionContainerClass
                    shouldScroll={!noFavorites}
                  />
                )}
              </div>
              <ForecastOutlookContextProvider
                hasCoreForecastPermissions={hasCoreForecastPermissions}
                surfHeightUnit={surfHeightUnit}
              >
                {!noFavorites && (
                  <FavoritesForecast
                    selectedFavorites={selectedFavorites as UserFavorites}
                    selectedRegions={selectedRegions}
                    scrollRef={scrollRef}
                  />
                )}
              </ForecastOutlookContextProvider>
              {lessThanXFavorites && (
                <MissingFavourites
                  boldText={defaultMissingArgs.boldText}
                  normalText={defaultMissingArgs.normalText}
                />
              )}
            </TabPanel>
            {treatments[SL_WEB_CUSTOM_FORECASTS] === 'on' && (
              <TabPanel value={tab} index={2}>
                <FavoritesCustomForecast
                  scrollRef={scrollRef}
                  units={(userUnits || DEFAULT_UNITS) as Units}
                />
              </TabPanel>
            )}
          </>
        ) : (
          <FavoritesForm userFavorites={favorites} />
        )}
      </Box>
      {pathname === '/favorites' && (
        <ModalContextual
          backgroundColor={slTheme.palette.secondary.main}
          open={!!subregionForecast}
          returnHref={returnHref}
          scrollContainerId="forecaster-content-modal"
        >
          <SubregionForecast scrollContainerId="forecaster-content-modal" isSubregionListVisible />
        </ModalContextual>
      )}
    </Box>
  );
};

export default Favorites;

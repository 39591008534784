import { useMemo } from 'react';
import classNames from 'classnames';
import { Box, Typography } from '@mui/material';

import { StarCircle } from 'components/Icons';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';
import { getForecastContent } from 'selectors/subregion';
import { useAppSelector } from 'stores/hooks';
import type { ForecastContent, ForecastContentHighlight } from 'types/subregion';

import styles from './ForecastDayHeading.module.scss';

interface Props {
  date: Date | null;
  isSubregionForecastActive?: boolean;
}

const ForecastDayHeading: React.FC<Props> = ({ date, isSubregionForecastActive = false }) => {
  const isMobileView = useMaxWidthTablet();
  const forecastContent: ForecastContent = useAppSelector(getForecastContent);

  const isDayToWatch = useMemo(() => {
    if (!isSubregionForecastActive) return false;

    const formattedDate = date?.toISOString().split('T')[0];

    if (formattedDate === forecastContent?.today.date) {
      return forecastContent?.today.forecast.dayToWatch ?? false;
    }

    if (formattedDate === forecastContent?.tomorrow.date) {
      return forecastContent?.tomorrow.forecast.dayToWatch ?? false;
    }

    const highlight = forecastContent?.highlights.find(
      (forecastHighlight: ForecastContentHighlight) => forecastHighlight.date === formattedDate,
    );

    if (highlight) {
      return highlight.forecast?.dayToWatch ?? false;
    }
    return false;
  }, [date, forecastContent, isSubregionForecastActive]);

  if (!date) {
    return <div className={styles.loading} />;
  }

  // the date is already in the spots timezone, but represented as UTC
  // so we need to display the UTC date - NOT the local date (users browser timezone)
  const engDayName = new Intl.DateTimeFormat('en', { weekday: 'short', timeZone: 'UTC' });
  const dayStr = engDayName.format(date);

  return (
    <div className={styles.dayHeading} data-testid="forecast-day-table-heading-day-text">
      <Box className={styles.star}>{isDayToWatch && <StarCircle testId="day-to-watch" />}</Box>
      <Typography
        variant="callout1"
        className={classNames({
          [styles.day]: true,
          [styles.dayToWatch]: isDayToWatch,
        })}
      >
        {isMobileView ? dayStr[0].toUpperCase() : dayStr}
      </Typography>
      <Typography variant="callout1" className={styles.date}>
        {date?.getUTCDate()}
      </Typography>
    </div>
  );
};

export default ForecastDayHeading;

import { RefObject, useMemo } from 'react';
import classNames from 'classnames';
import { isNil, round } from 'lodash';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { Box, Button, Typography } from '@mui/material';
import { SkullIcon } from '@wavetrak/theme';

import { customForecastOverview } from 'common/api/customForecast';
import { PremiumCustomForecastFavoritesCTA } from 'components/CTAs/CTAs';
import ForecastDayTable from 'components/ForecastDayTable';
import WavetrakLink from 'components/WavetrakLink';
import createDayHourlyData from 'reducers/components/graphs/helpers/createDayHourlyData';
import type { CustomForecastSwellsResponseDataPoint } from 'types/customForecast';
import type { Units } from 'types/units';
import type { DaySelectorOverview } from 'types/daySelector';
import {
  convertLatitudeToCardinal,
  convertLatOrLonForDisplay,
  convertLongitudeToCardinal,
} from 'utils/locations';
import { formatTimestampInTimezone } from 'utils/time';
import { useUserPermissionStatus } from 'selectors/user';

import { useForecastDaysAllowed } from 'utils/forecastDaysAllowed';
import styles from './FavoritesCustomForecast.module.scss';

const TEN_MINUTES_IN_MS = 60 * 10000;

interface ForecastOverviewDays {
  date: string;
  swell: { height: number; period: number };
  timestamp: number;
  utcOffset: number;
}

const paywallSegmentProps = {
  location: 'custom forecast',
  pageName: 'Favorites',
  category: 'forecast',
};

const reshapeDayHourlyData = ({
  swells,
  timestamp,
  utcOffset,
}: CustomForecastSwellsResponseDataPoint) => ({
  date: formatTimestampInTimezone(timestamp, utcOffset, 'yyyy-MM-dd'),
  swell: {
    height: round(swells?.combined?.height, 1),
    period: round(swells?.combined?.period, 0),
  },
  timestamp,
  utcOffset,
});

interface Props {
  scrollRef?: RefObject<HTMLDivElement>;
  units?: Units;
}

const FavoritesCustomForecast = ({ scrollRef, units }: Props) => {
  const router = useRouter();
  const { hasCustomForecastPermissions } = useUserPermissionStatus();

  const noCache = useMemo(
    () => router?.query?.noCache?.toString() === 'true',
    [router?.query?.noCache],
  );

  const { data, error, isLoading } = useSWR(
    units?.swellHeight ? { units, noCache } : undefined,
    customForecastOverview,
    {
      dedupingInterval: TEN_MINUTES_IN_MS,
      errorRetryCount: 0,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    },
  );

  const { forecastDaysAllowed } = useForecastDaysAllowed({
    type: 'favorites',
    isEntitled: hasCustomForecastPermissions,
  });

  const forecasts = useMemo<DaySelectorOverview[] | undefined>(() => {
    if (isLoading || error) return undefined;

    return data?.data?.overview?.map((forecast) => ({
      spotId: forecast._id,
      spotName: forecast.name,
      subtitle: `${convertLatOrLonForDisplay(forecast.lat)}${convertLatitudeToCardinal(
        forecast.lat,
      )}, ${convertLatOrLonForDisplay(forecast.lon)}${convertLongitudeToCardinal(forecast.lon)}`,
      days: createDayHourlyData<CustomForecastSwellsResponseDataPoint, ForecastOverviewDays>(
        forecast.swells,
        forecastDaysAllowed,
        1,
        reshapeDayHourlyData,
      ).map((day) => ({
        ...day?.[11], // grab noon data point
      })),
      swell: forecast.swells.map((swell) => ({
        ...swell.swells.combined,
        timestamp: swell.timestamp,
        utcOffset: swell.utcOffset,
      })),
    }));
  }, [data?.data?.overview, forecastDaysAllowed, error, isLoading]);

  if (error) {
    return (
      <Box className={styles.alertBox} data-testid="favorites-custom-forecast-error">
        <SkullIcon size="large" />
        <Typography variant="headline" mt={2} mb={3}>
          We weren’t able to load your custom forecasts.
        </Typography>
        <Button
          disableFocusRipple
          disableRipple
          disableTouchRipple
          href="/favorites?type=custom-forecast"
          variant="primary"
        >
          Try again
        </Button>
      </Box>
    );
  }

  if (!isLoading && (isNil(forecasts) || forecasts?.length === 0)) {
    return (
      <Box className={styles.alertBox} data-testid="favorites-custom-forecast-empty">
        <Typography variant="headline">Your saved custom forecasts will show up here.</Typography>
        <Typography variant="body1" mb={3}>
          Create a custom forecast for anywhere in the ocean on the Charts page.
        </Typography>
        <Button
          component={WavetrakLink}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          href="/surf-charts/"
          variant="primary"
        >
          Try it out
        </Button>
      </Box>
    );
  }

  return (
    <Box className={styles.forecastDayTableWrapper} data-testid="favorites-custom-forecast-table">
      <ForecastDayTable
        forecasts={forecasts}
        isCustomForecast
        scrollRef={scrollRef}
        units={units as Units}
      />
    </Box>
  );
};

const FavoritesCustomForecastWithPaywall: React.FC<Props> = (props) => {
  const { hasCustomForecastPermissions } = useUserPermissionStatus();

  if (hasCustomForecastPermissions) {
    return <FavoritesCustomForecast {...props} />;
  }

  return (
    <Box className={classNames(styles.alertBox, styles.paywall)}>
      <PremiumCustomForecastFavoritesCTA segmentProperties={paywallSegmentProps} />
    </Box>
  );
};

export default FavoritesCustomForecastWithPaywall;

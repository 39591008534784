import { type FunctionComponent, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Box, Button, Card, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import StarCircle from 'components/Icons/StarCircle';
import Information from 'components/Information';
import { SubregionForecastContentPaywallDialogFooter } from 'components/SubregionForecastPaywalls';
import { PRODUCT_TUTORIALS } from 'common/constants';
import PadlockIcon from 'components/Icons/Padlock';
import ForecasterProfile from 'components/ForecasterProfile';
import SubregionForecastLink from 'components/SubregionForecastLink';
import { useUserPermissionStatus } from 'selectors/user';
import type { ForecastContent } from 'types/subregion';
import { isAfterGivenHour } from 'utils/time';

import styles from './SubregionForecastContentNow.module.scss';

interface Props {
  className?: string;
  elevation?: number;
  forecastContent?: ForecastContent;
  forecastNote?: string | null;
  isLoading?: boolean;
  isTitleOnly?: boolean;
  pageName?: string;
}

const SubregionForecastContentNow: FunctionComponent<Props> = ({
  className,
  elevation,
  forecastContent,
  forecastNote,
  isLoading,
  isTitleOnly = false,
  pageName,
}) => {
  const { query } = useRouter();
  const { hasSurfReportPermissions } = useUserPermissionStatus();

  const isNative = query?.native?.toString() === 'true';
  const upgradeUrl = isNative ? 'surfline://upgrade' : '/upgrade';

  const isAfter2pm = useMemo(() => {
    if (!forecastContent?.timezone) return false;
    return isAfterGivenHour(forecastContent?.timezone, 14);
  }, [forecastContent]);

  const nowTitle = useMemo(() => {
    if (!forecastContent) return null;
    const forecast = forecastContent.tomorrowIsLive
      ? forecastContent.tomorrow.forecast
      : forecastContent.today.forecast;
    return (
      <>
        {forecast.dayToWatch && <StarCircle className={styles.star} />}{' '}
        {forecastContent.tomorrowIsLive ? 'Tomorrow' : 'Today'}
      </>
    );
  }, [forecastContent]);

  const segmentPropsLock = useMemo(
    () => ({
      location: 'Forecast Content Lock',
      modalType: 'paywall',
      subregionId: forecastContent?.subregion.id,
      subregionName: forecastContent?.subregion.name,
    }),
    [forecastContent],
  );

  const onClickHandler = useCallback(() => {
    trackEvent('Clicked CTA', { ...segmentPropsLock });
  }, [segmentPropsLock]);

  if (isLoading)
    return (
      <Skeleton
        animation="wave"
        className={styles.loadingNow}
        data-testid="subregion-forecast-content-now-loading"
        variant="rectangular"
      />
    );

  if (!forecastContent) return null;

  return (
    <Card
      className={classNames(styles.forecasterContentNow, className)}
      component="section"
      elevation={elevation}
      data-testid="subregion-forecast-content-now"
    >
      <Box padding={1}>
        <Stack component="header" direction="row" mb={2}>
          <Box sx={{ width: '100%' }}>
            <ForecasterProfile
              conditions=""
              iconUrl={forecastContent.forecaster.avatarUrl}
              isShowConditionsBorder={false}
              isShowName
              lastUpdate={{ timestamp: forecastContent.lastPublished }}
              name={forecastContent.forecaster.name}
              title={nowTitle}
            />
          </Box>
          <Box>
            <SubregionForecastLink
              contentBlock={forecastContent.tomorrowIsLive ? 'tomorrow' : 'today'}
              forecasterName={forecastContent.forecaster.name}
              pageName={pageName}
              start={forecastContent.tomorrowIsLive ? 'forecast-tomorrow' : 'forecast-today'}
              subregionId={forecastContent.subregion.id}
              subregionName={forecastContent.subregion.name}
            />
          </Box>
        </Stack>
        {!hasSurfReportPermissions && (
          <>
            {!isAfter2pm && !forecastContent.tomorrowIsLive && (
              <Typography component="h5" variant="body" mb={2}>
                Today&apos;s Forecast will be available to you at 2pm.
              </Typography>
            )}
            <Stack
              direction="row"
              spacing={1}
              mt={2}
              mb={isAfter2pm || forecastContent.tomorrowIsLive ? 2 : 0}
            >
              <Information
                DialogFooter={
                  <SubregionForecastContentPaywallDialogFooter
                    segmentProps={segmentPropsLock}
                    upgradeUrl={upgradeUrl}
                  />
                }
                articleId={PRODUCT_TUTORIALS.articles.paywallForecasterContent.id}
                theme="cinematic"
              >
                <IconButton
                  aria-label="Premium content is locked until 2pm. Upgrade to see it now."
                  className={classNames({
                    [styles.paywallLockButton]: true,
                    [styles.paywallLockButtonUnlocked]:
                      isAfter2pm && !forecastContent.tomorrowIsLive,
                  })}
                  data-testid="subregion-forecast-content-now-paywall-button"
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  onClick={onClickHandler}
                >
                  <PadlockIcon locked={!isAfter2pm || forecastContent.tomorrowIsLive} />
                </IconButton>
              </Information>
              <Button
                data-testid="subregion-forecast-content-now-upgrade-button"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                href={upgradeUrl}
                onClick={onClickHandler}
                variant="primary"
              >
                {isAfter2pm && !forecastContent.tomorrowIsLive
                  ? 'Get it earlier with Premium'
                  : 'Upgrade to see it now'}
              </Button>
            </Stack>
          </>
        )}
        {(hasSurfReportPermissions || (isAfter2pm && !forecastContent.tomorrowIsLive)) && (
          <>
            <Typography component="h5" variant="body" mb={2}>
              {forecastContent.tomorrowIsLive
                ? forecastContent.tomorrow.forecast.headline
                : forecastContent.today.forecast.headline}
            </Typography>
            {!isTitleOnly && (
              <Typography component="p" variant="body1">
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: forecastContent.tomorrowIsLive
                      ? forecastContent.tomorrow.forecast.observation
                      : forecastContent.today.forecast.observation,
                  }}
                />
              </Typography>
            )}
            {!isTitleOnly && forecastNote && (
              <Typography className={styles.forecastNotes} component="div" variant="body1">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: forecastNote }} />
              </Typography>
            )}
          </>
        )}
      </Box>
    </Card>
  );
};

export default SubregionForecastContentNow;

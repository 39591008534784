import React, { RefObject, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import ForecastDayTable from 'components/ForecastDayTable';
import { ForecastOutlookContext } from 'contexts/ForecastOutlookContext';
import type { UserFavorites } from 'types/userFavorites';
import { Units } from 'types/units';

interface Props {
  selectedFavorites: UserFavorites;
  selectedRegions: Array<string>;
  scrollRef?: RefObject<HTMLDivElement>;
}

const FavoritesForecast: React.FC<Props> = ({ selectedFavorites, selectedRegions, scrollRef }) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const { forecasts, requestXForecasts } = useContext(ForecastOutlookContext);

  const isSubregionForecastActive = useMemo(
    () =>
      !!(
        selectedRegions?.length === 1 &&
        selectedFavorites?.length &&
        selectedRegions[0] !== 'all' &&
        selectedFavorites[0]?.forecastStatus === 'active'
      ),
    [selectedFavorites, selectedRegions],
  );

  const filteredForecasts = useMemo(
    () =>
      (forecasts?.data?.overview?.length ?? 0) > 0
        ? forecasts?.data?.overview?.filter?.((forecast) =>
            selectedFavorites?.find((fav) => fav._id === forecast.spotId),
          )
        : undefined,
    [forecasts?.data?.overview, selectedFavorites],
  );

  const infiniteScrollTriggerRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            requestXForecasts(selectedFavorites, 5);
          }
        },
        { rootMargin: '100px' },
      );
      if (node) observer.current.observe(node);
    },
    [requestXForecasts, selectedFavorites],
  );

  const forecastDayTableProps = useMemo(
    () => ({
      scrollRef,
      forecasts: filteredForecasts,
      units: forecasts?.associated?.units || ({ waveHeight: 'ft' } as Units),
      isSubregionForecastActive,
    }),
    [filteredForecasts, isSubregionForecastActive, forecasts?.associated?.units, scrollRef],
  );

  useEffect(() => {
    if (selectedFavorites) requestXForecasts(selectedFavorites.slice(0, 5), 5);
  }, [requestXForecasts, selectedFavorites]);

  return (
    <>
      <ForecastDayTable {...forecastDayTableProps} />
      {filteredForecasts?.length !== selectedFavorites?.length && (
        <div ref={infiniteScrollTriggerRef} />
      )}
    </>
  );
};

export default FavoritesForecast;

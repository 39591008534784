import React, { ReactNode, useCallback, useMemo } from 'react';
import MUIModal from '@mui/material/Modal';
import { Button, Slide, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import styles from './ModalContextual.module.scss';

const slotProps = {
  backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.8)', outline: 'none' } },
};

export interface ModalContextualProps {
  backgroundColor?: string;
  children: ReactNode;
  open: boolean;
  returnHref: string;
  scrollContainerId?: string;
}

export const ModalContextual = ({
  backgroundColor = 'white',
  children,
  open,
  returnHref,
  scrollContainerId,
}: ModalContextualProps) => {
  const { push } = useRouter();

  const handleClose = useCallback(() => {
    push(returnHref, undefined, { shallow: true });
  }, [push, returnHref]);

  const contentStyle = useMemo(() => ({ backgroundColor }), [backgroundColor]);

  return (
    <MUIModal
      className="wavetrak-ad-ignore"
      open={open}
      onClose={handleClose}
      slotProps={slotProps}
      data-testid="modal-contextual"
      keepMounted
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <div className={styles.modalContextual} id={scrollContainerId}>
          <header className={styles.header} data-testid="modal-contextual-header">
            <Typography variant="body" color="text.secondary" className={styles.headerHint}>
              Hint: Hit <span>esc</span> to exit
            </Typography>
            <Button
              className={styles.headerClose}
              color="dark"
              data-testid="modal-contextual-close"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={handleClose}
              size="small"
              variant="secondary"
            >
              Close X
            </Button>
          </header>
          <section
            className={styles.content}
            data-testid="modal-contextual-content"
            style={contentStyle}
          >
            {children}
          </section>
        </div>
      </Slide>
    </MUIModal>
  );
};

export default ModalContextual;

import { useEffect, useState, useCallback } from 'react';
import Link from 'next/link';
import { Button, IconButton, Typography, useTheme } from '@mui/material';

import { ChevronLeft } from 'components/Icons/ChevronLeft';
import { ChevronRight } from 'components/Icons/ChevronRight';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';

import styles from './ControlBarScrollButtons.module.scss';

interface CtaButtonProps {
  isDark?: boolean;
  isExternal?: boolean;
  link: string;
  linkAs?: string;
  mobile?: boolean;
  onClickCallback?: Function;
  text: string;
}

const CtaButton: React.FC<CtaButtonProps> = ({
  isDark,
  isExternal = false,
  link,
  linkAs,
  onClickCallback,
  text,
}) => {
  const theme = useTheme();
  const isMobile = useMaxWidthTablet();

  const onClickButtonHandler = useCallback(() => {
    if (onClickCallback) onClickCallback();
    if (isExternal) {
      window.location.href = link;
    }
  }, [onClickCallback, isExternal, link]);

  const renderButton = () => (
    <Button
      disableRipple
      variant={isMobile ? 'text' : 'secondary'}
      color={isDark && !isMobile ? 'dark' : 'primary'}
      style={
        isMobile
          ? { color: isDark ? theme.palette.common.white : theme.palette.text.primary, padding: 0 }
          : {}
      }
      className={styles.callToActionButton}
      data-testid="sl-control-bar-cta-button"
      onClick={onClickButtonHandler}
    >
      {text}
    </Button>
  );

  return isExternal ? (
    renderButton()
  ) : (
    <Link href={link} as={linkAs} shallow data-testid="sl-content-carousel-call-to-action-button">
      {renderButton()}
    </Link>
  );
};

interface ControlBarScrollButtonsProps {
  showPageCount?: {
    currentPage: number;
    pages: number;
  };
  callToActionButton?: {
    link: string;
    linkAs?: string;
    text: string;
    isDark?: boolean;
    onClickCallback?: Function;
  };
  scrollByPercent?: number;
  shouldScroll: boolean;
  sectionContainerClass?: boolean;
  scrollRef: HTMLDivElement | null;
  debouncedScrollHandlers?: {
    left: (action: string) => void;
    right: (action: string) => void;
  };
}

const ControlBarScrollButtons: React.FC<ControlBarScrollButtonsProps> = ({
  showPageCount,
  callToActionButton,
  scrollByPercent = 100,
  shouldScroll,
  scrollRef,
  debouncedScrollHandlers,
}) => {
  const [maxScrollRight, setMaxScrollRight] = useState(0);
  const [disableRightButton, setDisableRightButton] = useState(false);
  const [disableLeftButton, setDisableLeftButton] = useState(true);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [scrollPx, setScrollPx] = useState(0);

  const handleOnScroll = useCallback(
    (event: Event) => {
      if (event?.currentTarget) {
        const { scrollLeft } = event.currentTarget as HTMLDivElement;
        setCurrentScroll(scrollLeft);
        const direction = scrollLeft - currentScroll;
        if (debouncedScrollHandlers)
          if (direction > 0) debouncedScrollHandlers.right('scroll');
          else debouncedScrollHandlers.left('scroll');
      }
    },
    [currentScroll, debouncedScrollHandlers],
  );

  const resetScroll = useCallback(() => {
    if (scrollRef) {
      setScrollPx(scrollRef.clientWidth * (scrollByPercent / 100));
      setMaxScrollRight(scrollRef.scrollWidth - scrollRef.clientWidth);
    }
  }, [scrollByPercent, scrollRef]);

  const scroll = useCallback(
    (direction: number) => {
      if (scrollRef) {
        const scrollOffset = scrollPx * direction;
        scrollRef.scrollBy({ left: scrollOffset });
        if (debouncedScrollHandlers)
          if (direction > 0) debouncedScrollHandlers.right('click');
          else debouncedScrollHandlers.left('click');
      }
    },
    [scrollRef, scrollPx, debouncedScrollHandlers],
  );

  const handleScrollLeft = useCallback(() => {
    scroll(-1);
  }, [scroll]);

  const handleScrollRight = useCallback(() => {
    scroll(1);
  }, [scroll]);

  useEffect(() => {
    if (scrollRef) {
      scrollRef.addEventListener('scroll', handleOnScroll);
      return () => {
        scrollRef.removeEventListener('scroll', handleOnScroll);
      };
    }
    return () => {};
  }, [handleOnScroll, scrollRef]);

  useEffect(() => {
    if (scrollRef) {
      resetScroll();
      setCurrentScroll(scrollRef.scrollLeft);
    }
  }, [scrollRef, resetScroll, scrollRef?.scrollWidth]);

  useEffect(() => {
    window.addEventListener('resize', resetScroll);
    window.addEventListener('orientationchange', resetScroll);
    return () => {
      window.removeEventListener('resize', resetScroll);
      window.removeEventListener('orientationchange', resetScroll);
    };
  }, [resetScroll]);

  useEffect(() => {
    setDisableRightButton(!shouldScroll || Math.ceil(currentScroll) >= maxScrollRight);
    setDisableLeftButton(!shouldScroll || Math.ceil(currentScroll) === 0);
  }, [currentScroll, maxScrollRight, shouldScroll]);

  return (
    <div className={styles.buttons}>
      {showPageCount && (
        <Typography variant="body">
          Page {showPageCount.currentPage} of {showPageCount.pages}
        </Typography>
      )}
      {callToActionButton && <CtaButton {...callToActionButton} />}
      {shouldScroll && (
        <div className={styles.scrollArrows}>
          <IconButton
            className={styles.arrowButton}
            data-testid="sl-previous-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disabled={disableLeftButton}
            onClick={handleScrollLeft}
            size="small"
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            className={styles.arrowButton}
            data-testid="sl-next-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disabled={disableRightButton}
            onClick={handleScrollRight}
            size="small"
          >
            <ChevronRight />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default ControlBarScrollButtons;
